import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/54654bf8/website/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState } from 'react';
import { Playground } from 'docz';
import Storage from '@mini-code/base-func/storage';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "other"
    }}>{`Other`}</h1>
    <blockquote>
      <p parentName="blockquote">{`basic-helper 提供了其他的辅助函数，但这些辅助函数并不包含在 basic-helper 的默认导出中，需要指定引入`}</p>
    </blockquote>
    <h2 {...{
      "id": "storage"
    }}>{`Storage`}</h2>
    <Playground __position={0} __code={'() => {\n  if (global) {\n    return <span></span>\n  }\n  let inputRef\n  const storageRef = \'FOR_TEST\'\n  const initStorage = Storage.getItem(storageRef)\n  const [content, setState] = useState(initStorage)\n  return (\n    <div>\n      <div>本地存储内容: {content + \'\'}</div>\n      <hr />\n      <input\n        className=\"form-control\"\n        ref={e => (inputRef = e)}\n        style={{ width: 300 }}\n        defaultValue={content}\n      />\n      <span\n        className=\"btn theme mr10\"\n        onClick={e => {\n          setState(inputRef.value)\n          Storage.setItem(storageRef, inputRef.value)\n        }}\n      >\n        存储到本地\n      </span>\n      <span\n        className=\"btn red mr10\"\n        onClick={e => {\n          Storage.setItem(storageRef, inputRef.value)\n        }}\n      >\n        获取储存的数据\n      </span>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Storage
    }} mdxType="Playground">
  {() => {
        if (global) {
          return <span></span>;
        }

        let inputRef;
        const storageRef = 'FOR_TEST';
        const initStorage = Storage.getItem(storageRef);
        const [content, setState] = useState(initStorage);
        return <div>
          <div>本地存储内容: {content + ''}</div>
          <hr />
          <input className="form-control" ref={e => inputRef = e} style={{
            width: 300
          }} defaultValue={content} />
          <span className="btn theme mr10" onClick={e => {
            setState(inputRef.value);
            Storage.setItem(storageRef, inputRef.value);
          }}>存储到本地</span>
          <span className="btn red mr10" onClick={e => {
            Storage.setItem(storageRef, inputRef.value);
          }}>获取储存的数据</span>
        </div>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      